import { render, staticRenderFns } from "./parklotdetail.vue?vue&type=template&id=881518a6&scoped=true&"
import script from "./parklotdetail.vue?vue&type=script&lang=js&"
export * from "./parklotdetail.vue?vue&type=script&lang=js&"
import style0 from "./parklotdetail.vue?vue&type=style&index=0&id=881518a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881518a6",
  null
  
)

export default component.exports